import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

enum buttonType {
  ROW = 'Row',
  EXPANDER = 'Expander',
  PAGE = 'Page',
  CHECKBOX = 'Checkbox',
  RADIO = 'Radio',
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  // define action on button-click
  @Output() action = new EventEmitter<boolean>();
  // describe function
  @Input() toolTipDescription!: string;
  // IconName type
  @Input() iconSelector!: IconName;
  // method show/hide icon/function
  @Input() visible = true;
  // set enum for buttonType
  @Input() buttonType: string = buttonType.ROW;
  // toggle-option for EXPANDER
  @Input() expanded!: any;
  // true/false value for toggle icons
  @Input() toggle!: any;
  // toggle disabled-property
  @Input() disabled = false;

  constructor() {}

  ngOnInit() {}

  public clickAction() {
    this.action.emit(true);
  }

  public isButtonTypeRow(): boolean {
    return this.buttonType === buttonType.ROW;
  }

  public isButtonTypeExpander(): boolean {
    return this.buttonType === buttonType.EXPANDER;
  }

  public isButtonTypePage(): boolean {
    return this.buttonType === buttonType.PAGE;
  }

  public isButtonTypeCheckbox(): boolean {
    return this.buttonType === buttonType.CHECKBOX;
  }

  public isButtonTypeRadio(): boolean {
    return this.buttonType === buttonType.RADIO;
  }
}
