<!-- Type Row -->
<button
  [disabled]="disabled"
  *ngIf="isButtonTypeRow() && visible"
  class="option-button"
  (click)="clickAction()"
>
  <fa-icon
    [icon]="['fas', iconSelector]"
    [ngClass]="disabled ? 'option-svg-disabled' : 'option-svg'"
    ngbTooltip="{{ toolTipDescription }}"
    placement="bottom"
    container="body"
    data-container="body"
    tooltipClass="note-tooltip"
    [openDelay]="500"
    container="body"
  ></fa-icon>
</button>

<button
  *ngIf="isButtonTypeRow() && !visible"
  class="option-button invisible"
></button>

<!-- Type Checkbox -->
<button
  [disabled]="disabled"
  *ngIf="isButtonTypeCheckbox()"
  class="option-button"
  (click)="clickAction()"
>
  <fa-icon *ngIf="!toggle" icon="square"></fa-icon>
  <fa-icon *ngIf="toggle" icon="check-square"></fa-icon>
</button>

<!-- Type Radio -->
<button
  [disabled]="disabled"
  *ngIf="isButtonTypeRadio()"
  class="option-button"
  (click)="clickAction()"
>
  <fa-icon *ngIf="!toggle" icon="circle"></fa-icon>
  <fa-icon *ngIf="toggle" icon="dot-circle"></fa-icon>
</button>

<!-- Type Expander -->
<a
  *ngIf="isButtonTypeExpander()"
  class="toggle-icon"
  href="javascript:void(0)"
  ngbTooltip="{{ toolTipDescription }}"
  placement="bottom"
  container="body"
  data-container="body"
  tooltipClass="note-tooltip"
  [openDelay]="500"
  container="body"
  (click)="clickAction()"
  container="body"
>
  <fa-icon *ngIf="!expanded" icon="angle-down"></fa-icon>
  <fa-icon *ngIf="expanded" icon="angle-up"></fa-icon>
</a>

<!-- Type Page -->
<button
  *ngIf="isButtonTypePage()"
  [disabled]="disabled"
  (click)="clickAction()"
  class="btn btn-primary round-button"
>
  <fa-icon
    class="round-button-icon"
    [icon]="['fas', iconSelector]"
    ngbTooltip="{{ toolTipDescription }}"
    placement="bottom"
    container="body"
    data-container="body"
    tooltipClass="note-tooltip"
    [openDelay]="500"
    container="body"
    (click)="clickAction()"
    container="body"
  ></fa-icon>
</button>
