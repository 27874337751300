import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CalculationLogFilesService } from '@core/services/calculation-log-file.service';
import { CalculationService } from '@core/services/calculation.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { StorageService } from '@core/services/storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ControlUnits } from '@shared/models/ControlUnits';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { CalculationLogFiles } from '@shared/models/filetype/CalculationLogFiles';
import { ModalResult } from '@shared/models/ModalResult';
import { CheckBoxSelect } from '@shared/models/Select';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SharedSeriesService } from '@core/services/shared-series.service';

@Component({
  selector: 'app-fcsc-log-files-modal',
  templateUrl: './fcsc-log-files-modal.component.html',
  styleUrls: ['./fcsc-log-files-modal.component.scss'],
})
export class FcscLogFilesModalComponent implements OnInit {
  sortConfig = [{ prop: 'upload_date', dir: 'desc' }];
  @Input() fin!: string;
  @Input() handleFileOpen!: any;
  calculationLogFiles: CalculationLogFiles[] = [];
  title = 'modules.expert-tool.result.log.title-log-download';
  subTitle = 'modules.expert-tool.result.log.log-file-download-subtitle';
  loadingTitle = 'global.loading-spinner-fetch-title';
  loadingSubtitle = 'global.loading-spinner-fetch-subtitle';
  isLoading = false;
  selected: CalculationLogFiles[] = [];
  dataTableSelection = SelectionType.checkbox;
  requestCounterMap = {};
  file: File | null = null;
  convertedJsonData!: ExpertToolCalculation | null;
  controlUnits: ControlUnits[];

  constructor(
    public calculationLogFilesService: CalculationLogFilesService,
    public activeModal: NgbActiveModal,
    public downloadService: DownloadService,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private calculationService: CalculationService,
    private storageService: StorageService,
    private expertoolDataModelingService: ExperttoolDataModelingService,
    private sharedSeriesService : SharedSeriesService
  ) {}

  ngOnInit(): void {
    this.getLogFiles(this.fin);
  }

  onSelect(event: CheckBoxSelect): void {
    this.selected = [...event?.selected];
  }

  private getLogFiles(fin: string) {
    this.isLoading = true;
    this.calculationLogFilesService.getCalculationLogFiles(fin).subscribe(
      (data: CalculationLogFiles[]) => {
        this.isLoading = false;

        const combinedList = data
          .map((item) => {
            return this.splitLogFilesItems(item);
          })
          .sort((a: any, b: any) => {
            const dateA = a.requestId;
            const dateB = b.requestId;
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            return 0;
          })
          .map((item) => {
            item.requestCounter = this.requestCounter(item.requestId);
            return item;
          });
        this.calculationLogFiles = combinedList;

        this.selected = [];
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  private splitLogFilesItems(item: any) {
    const mappedName = item.name.substring(item.name.indexOf('/') + 1);
    const fields = mappedName.split('_');
    const checkSystemField = mappedName.includes('experttool')
      ? this.translocoService.translate(
          'modules.expert-tool.result.log.datatable.system-expert'
        )
      : 'Xentry';
    const ReqField = fields[1] ? fields[1].split('.') : '';
    item.finOrVin = ReqField[0];
    item.requestId = ReqField[1];
    item.fileName = fields[2] ? fields[2] : '';
    item.system = checkSystemField;
    item.shortName = mappedName.toString();
    return item;
  }

  private requestCounter(requestId: string): string {
    const currentMap = this.requestCounterMap;
    const requestDate = requestId;
    const requestCounterFromMap = currentMap[requestDate];
    if (requestId?.includes(requestDate)) {
      if (requestCounterFromMap) {
        return requestCounterFromMap;
      } else {
        const counterKeys: string[] = Object.values(currentMap);
        const lastCounter: string = counterKeys[counterKeys.length - 1];
        const newCounter = counterKeys.length ? +lastCounter + 1 : 0;
        currentMap[requestDate] = this.getCounterNumber(newCounter);
        this.requestCounterMap = currentMap;
      }
    }
    return currentMap[requestDate];
  }

  private getCounterNumber(num: number) {
    if (num < 999) {
      return ('000' + num).slice(-3);
    }
    return '' + num;
  }

  handleDownload() {
    this.selected.forEach((element) => {
      this.exportFile(element);
    });
  }

  public exportFile(file: CalculationLogFiles) {
    this.calculationLogFilesService.getPresignedUrlForFile(file).subscribe(
      (response: any) => {
        const url = response?.presignedUrl.toString();
        this.calculationLogFilesService
          .getExportFileFromPresignedUrl(url)
          .subscribe(
            (res: Blob) => {
              this.downloadService.downloadFile(res, file.shortName);
            },
            (error) => {
              this.notificationService.handleServerErrorNotification(
                error,
                'modules.admin-section.config-modal.insert-edit-api-error-message'
              );
            }
          );
      },
      (error) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  readFinlogFile(fileBlob?: any, fileObj?: any) {
    const fileReader: FileReader = new FileReader();
    const fileName = fileObj?.name?.split('/')[1];
    this.file = fileBlob;
    fileReader.onload = (e) => {
      this.convertXmlFileToJson(fileReader?.result, fileName);
    };
    this.checkFilesReading(fileReader);
  }

  private convertXmlFileToJson(data, fileName) {
    this.isLoading = true;
    this.calculationService.putConvertXmlToJson(data).subscribe(
      (response: HttpResponse<ExpertToolCalculation | null>) => {
        this.isLoading = false;
        this.convertedJsonData = response.body;
        this.storageService.saveData(
          sessionStorageKeys.calculationData,
          this.convertedJsonData
        );

        this.storageService.saveData(sessionStorageKeys.readFile, true);
        this.createVedocDataFromResponse(fileName);
      },
      (error) => {
        this.isLoading = false;
        this.clearDataWhenError();

        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.encrypt-error'
        );
      }
    );
  }

  private async createVedocDataFromResponse(fileName) {
    const vedocData =
      this.expertoolDataModelingService.mapVedocDataFromCalculationResponse(
        this.convertedJsonData as ExpertToolCalculation
      );
    this.expertoolDataModelingService.createVedocDataFromResponse(
      this.convertedJsonData as ExpertToolCalculation,
      vedocData
    );
    this.isLoading = true;
    this.expertoolDataModelingService.baureiheList = await this.sharedSeriesService.getSeries();
    this.isLoading = false;
    this.handleFileOpen(vedocData, fileName);
  }

  private checkFilesReading(fileReader) {
    const vin = this.storageService.getData(sessionStorageKeys.vin);
    if (this.convertedJsonData || vin) {
      this.storageService.clearData();
    }
    setTimeout(() => {
      fileReader.readAsText(this.file);
    }, 100);
  }

  public handleFileRead(file: CalculationLogFiles) {
    this.isLoading = true;
    this.calculationLogFilesService.getPresignedUrlForFile(file).subscribe(
      (response: any) => {
        const url = response?.presignedUrl.toString();
        this.calculationLogFilesService
          .getExportFileFromPresignedUrl(url)
          .subscribe(
            (res: Blob) => {
              this.isLoading = false;
              this.readFinlogFile(res, file);
            },
            (error) => {
              this.isLoading = false;
              this.notificationService.handleServerErrorNotification(
                error,
                'modules.admin-section.config-modal.insert-edit-api-error-message'
              );
            }
          );
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  private clearDataWhenError() {
    this.storageService.clearData();
    this.notificationService.showError(
      '',
      this.translocoService.translate('modules.expert-tool.wrong-finlog-file')
    );
  }

  public cancelAction() {
    this.activeModal.close(ModalResult.WARNING_CANCEL);
  }

  checkFileType(row) {
    return row.fileName.includes('xml');
  }
}
