<ng-container  *ngIf="navItems && navItems.length > 0" > 
  <ng-container   *ngFor="let nav of navItems; let index = index" > 
  <div class="vus-breadcrumb" *appRequiredEntitlements="nav.entitlementKey ? nav.entitlementKey : []"> 
  <span>
    <fa-icon class="container-title icon home-icon" [ngClass]="{'selected' : nav.isSelected}"
      icon="home" *ngIf="!nav.title" (click)="onItemSelect(nav.path, index, true)"></fa-icon>
    <fa-icon class="container-title icon chevron-icon" icon="chevron-right" *ngIf="index > 0"></fa-icon>
    <span  class="container-title text" [ngClass]="{'selected' : nav.isSelected}"
      (click)="onItemSelect(nav.path, index, false)" *ngIf="nav.title" >{{nav.title}}</span>
  </span>
</div>
</ng-container>
</ng-container>