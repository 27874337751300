import {Component, Input, OnInit} from '@angular/core';
import {TestautomationTest} from "@shared/models/testautomation/TestautomationTest";
import {StatusColor} from "@core/enums/status-color.enum";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {faCircle, fas} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss']
})
export class TestResultComponent implements OnInit {
  protected readonly StatusColor = StatusColor;
  @Input() recentTestRun: TestautomationTest;

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faCircle);
  }

  ngOnInit(): void {
    if (this.recentTestRun == null) {
      let date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
      this.recentTestRun = new TestautomationTest(date.toString(), 0, 0, 0, 'UNKNOWN');
    }
  }
}
