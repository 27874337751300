<div class="filter" *ngIf="formLoaded">
  <form [formGroup]="form" (keyup.enter)="filterRows()">
    <div>
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="baureihe">{{
              "modules.data-management.table-header.series" | transloco
            }}</label>
            <ng-select
              [searchable]="true"
              [clearable]="false"
              notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
              [hideSelected]="true"
              [items]="seriesList"
              bindLabel="brName"
              bindValue="brName"
              formControlName="baureihe"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.brName }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.brName }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="histDate">{{
              "modules.data-management.serien-daten.upload-list.hist-date" | transloco
            }}</label>

            <div class="input-group date-background-editable">
              <input
                #searchHistDate
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="histDate"
                name="dp"
                formControlName="histDate"
                ngbDatepicker
                #dHist="ngbDatepicker"
                readonly
                (click)="removeHistDateFocus()"
              />
              <div class="input-group-append">
                <fa-icon
                  class="dp"
                  icon="calendar-alt"
                  (click)="dHist.toggle()"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="minDatenstand">{{
              "modules.data-management.serien-daten.upload-list.min-date"
                | transloco
            }}</label>

            <div class="input-group date-background-editable">
              <input
                #searchMinDateStatus
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="minDatenstand"
                name="dp"
                formControlName="minDatenstand"
                ngbDatepicker
                #dMin="ngbDatepicker"
                readonly
                (click)="removeMinDateStatusFocus()"
              />
              <div class="input-group-append">
                <fa-icon
                  class="dp"
                  icon="calendar-alt"
                  (click)="dMin.toggle()"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="uploadDate">{{
              "modules.data-management.serien-daten.upload-list.upload-date"
                | transloco
            }}</label>

            <div class="input-group date-background-editable">
              <input
                #searchUploadDate
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="uploadDate"
                name="dp"
                formControlName="uploadDate"
                ngbDatepicker
                #dUpload="ngbDatepicker"
                readonly
                (click)="removeUploadDateFocus()"
              />
              <div class="input-group-append">
                <fa-icon
                  class="dp"
                  icon="calendar-alt"
                  (click)="dUpload.toggle()"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="contactPerson">{{
              "modules.data-management.serien-daten.upload-list.contact-person"
                | transloco
            }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="contactPerson"
              formControlName="contactPerson"
              placeholder="{{
                'modules.data-management.serien-daten.upload-list.placeholder.input-filter'
                  | transloco
              }}"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="prio">{{
              "modules.data-management.serien-daten.upload-list.prio"
                | transloco
            }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="prio"
              formControlName="prio"
              placeholder="{{
                'modules.data-management.serien-daten.upload-list.placeholder.input-filter'
                  | transloco
              }}"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="requirement">{{
              "modules.data-management.serien-daten.upload-list.requirement"
                | transloco
            }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="requirement"
              formControlName="requirement"
              placeholder="{{
                'modules.data-management.serien-daten.upload-list.placeholder.input-filter'
                  | transloco
              }}"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
          <div class="button-area">
            <button
              type="button"
              class="btn btn-primary"
              (click)="filterRows()"
            >
              {{ "modules.data-management.filter.btn-search" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="resetFilteredRows()"
            >
              {{ "modules.data-management.filter.btn-reset" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
