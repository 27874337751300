<div class="modal-header">
  <span class="modal-title">{{ "modules.data-management.serien-daten.modal-history.series-bdp-title" | transloco }}</span>
  <div (click)="activeModal.dismiss()">
    <fa-icon class="close-icon" icon="times"></fa-icon>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="filter" *ngIf="formLoaded">
      <form [formGroup]="searchForm" (keyup.enter)="filterRecords()">
        <div>
          <div class="row row-cols-auto">
            <div class="col-4">
              <div class="form-group">
                <label for="searchField">{{ "modules.data-management.serien-daten.modal-history.input-label" | transloco }}</label>
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control search"
                  formControlName="searchField"
                  placeholder="{{
                    'modules.data-management.serien-daten.modal-history.input-placeholder' | transloco
                  }}"
                />
                <!-- (keyup)="filterRecords()" -->
              </div>
            </div>

            <div class="col">
              <div class="button-area-search">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="filterRecords()"
                >
                  {{ "modules.data-management.filter.btn-search" | transloco }}
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="resetFilteredRows()"
                >
                  {{ "modules.data-management.filter.btn-reset" | transloco }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <p></p>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [rows]="filteredSeriesBdpHistoryFiles"
          [sorts]="sortConfig"
        >
          <ngx-datatable-column
            name="{{ 'global.file' | transloco }}"
            prop="name"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.data-management.table-header.files'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.uploaddate' | transloco }}"
            prop="createdAt"
            [flexGrow]="4"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date: "dd.MM.yy HH:mm:ss" }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.data-management.table-header.upload-date'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="2"
            [canAutoResize]="true"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-row="row"
              let-expanded="expanded"
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
            >
              <div class="float-right align-icons icon-box">
                <div
                  *appRequiredEntitlements="fileExportEntitlement"
                  class="option-button"
                >
                  <fa-icon
                    (click)="exportFile(row)"
                    class="option-svg"
                    icon="download"
                    placement="bottom"
                    ngbTooltip="{{
                      'modules.data-management.tooltip.download-control-data'
                        | transloco
                    }}"
                    data-container="body"
                    container="body"
                    tooltipClass="note-tooltip"
                  ></fa-icon>
                </div>
              </div>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
