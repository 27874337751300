import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateFormat } from '@core/enums/date-format.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '@shared/models/ModalResult';
import {
  SerialUploadList,
  SerialUploadListPayload,
} from '@shared/models/serialdata/SerialUploadList';

@Component({
  selector: 'app-fcsc-edit-upload-list',
  templateUrl: './fcsc-edit-upload-list.component.html',
  styleUrls: ['./fcsc-edit-upload-list.component.scss'],
})
export class FcscEditUploadListComponent implements OnInit {
  form!: UntypedFormGroup;
  @Input() data!: SerialUploadList;
  @ViewChild('searchMinDateStatus') searchMinDateStatus!: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    const isEdit = this.data !== undefined;

    this.form = this.formBuilder.group({
      baureihe: new UntypedFormControl(isEdit ? this.data.baureihe : ''),
      histDate: new UntypedFormControl(
        isEdit ? this.datePipe.transform(this.data.histDate, 'dd.MM.yy') : ''
      ),
      minDatenstand: new UntypedFormControl(''),
      uploadDate: new UntypedFormControl(
        isEdit ? this.datePipe.transform(this.data.uploadDate, 'dd.MM.yy') : ''
      ),
      contactPerson: new UntypedFormControl(isEdit ? this.data.contactPerson : ''),
      prio: new UntypedFormControl(isEdit ? this.data.prio : ''),
      requirement: new UntypedFormControl(isEdit ? this.data.requirement : ''),

      comment: new UntypedFormControl(isEdit ? this.data.comment : ''),
    });

    if (isEdit) {
      const defaultMinDate =
        this.data.minDatenstand !== null
          ? new Date(this.data.minDatenstand)
          : null;
      const settedMinDateVal =
        defaultMinDate !== null
          ? this.form.get('minDatenstand')?.setValue({
              year: defaultMinDate.getFullYear(),
              month: defaultMinDate.getMonth() + 1,
              day: defaultMinDate.getDate(),
            })
          : null;
      return settedMinDateVal;
    }
  }

  save() {
    const minDateValue = this.form.get('minDatenstand')?.value;
    const minDate = minDateValue
      ? new Date(
          minDateValue.year + '-' + minDateValue.month + '-' + minDateValue.day
        )
      : null;
    const minDateFormatted = this.datePipe.transform(
      minDate,
      DateFormat.YEAR_MONTH_DAY
    );
    const recordPayload: SerialUploadListPayload = {
      baureihe: this.form.get('baureihe')?.value,
      minDatenstand: minDateFormatted ? parseInt(minDateFormatted, 10) : null,
      histDate: this.data?.histDate,
      uploadDate: this.data?.uploadDate,
      contactPerson: this.form.get('contactPerson')?.value,
      prio: this.form.get('prio')?.value,
      requirement: this.form.get('requirement')?.value,
      comment: this.form.get('comment')?.value,
    };

    const finalResult = {
      ok: ModalResult.INSERT_SUCCESSFUL,
      recordPayload,
    };
    this.activeModal.close(finalResult);
  }

  cancel() {
    this.activeModal.close(null);
  }

  public removeMinDateStatusFocus() {
    this.searchMinDateStatus.nativeElement.blur();
  }
}
