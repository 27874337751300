export enum StatusColor {
  RED = '#FF0000',
  YELLOW = '#FFD559',
  GREEN = '#24A04A',
  DEFAULT = '#ffffff',
}

export enum Status {
  ALL = 'modules.data-management.filter.br-placeholder',
  SMOKE_TEST_FAILED = 'global.smoke-test-failed',
  SMOKE_TEST_PASSED = 'global.smoke-test-success',
  IN_SERIES = 'global.inserie',
  INITIAL = 'global.initial',
  SMOKE_TEST_FINLOG_NOT_FOUND = 'global.smoke-test-finlog-not-found',
  ABORTED = 'global.smoke-test-aborted',
  SMOKE_TEST_RUNNING = 'global.smoke-test-running'
}