import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { VersionService } from '@core/services/version/version.service';
import { apiPaths } from '@env/paths';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SeriesBdp } from '@shared/models/filetype/SeriesBdp';

import packageInfo from '../../../../package.json';
import packageLockInfo from '../../../../package-lock.json';
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  @Input() popover!: NgbPopover;
  public dataManagerVersion = '';
  public steuerungVersion = '';
  public uiVersion = '';
  public uiLockVersion = '';
  public vpVersion = '';

  constructor(
    private versionService: VersionService,
    private steuerdatenService: SteuerdatenService,
    private notificationService: ErrorHandlerService,
    private library: FaIconLibrary
  ) {library.addIcons(faTimes)}

  ngOnInit() {
    this.versionService.getUiVersion().subscribe(
      (gitHash) => (this.uiVersion = `${packageInfo.version} (${gitHash})`),
      (error) => (this.uiVersion = packageInfo.version)
    );
    this.versionService.getUiVersion().subscribe(
      (gitHash) => (this.uiLockVersion = `${packageLockInfo.version} (${gitHash})`),
      (error) => (this.uiVersion = packageInfo.version)
    );

    this.versionService
      .getDataManagerVersion()
      .subscribe((ver) => (this.dataManagerVersion = ver));

    this.versionService
      .getSteuerungVersion()
      .subscribe((ver) => (this.steuerungVersion = ver));

    this.getSeriesProductiveBdp();
  }

  private getSeriesProductiveBdp(): void {
    this.steuerdatenService.getSeriesProductiveBdp().subscribe(
      (seriesBdpData: SeriesBdp[]) => {
        const data = seriesBdpData[0];
        this.vpVersion = data?.vpVersion || '';
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }
}
