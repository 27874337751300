<div>
  <div class="modal-header">
    <span class="modal-title">{{
      "modules.data-management.serien-daten.upload-list.modal.title" | transloco
    }}</span>
  </div>

  <div class="modal-sub-text">
    {{ "modules.data-management.serien-daten.upload-list.modal.sub-title" | transloco }}
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row form-group">
        <div class="col-3">
          <div class="form-group">
            <label for="baureihe">{{
              "global.series" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="baureihe"
              formControlName="baureihe"
              readonly
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="histDate">{{
              "modules.data-management.serien-daten.upload-list.hist-date" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="histDate"
              formControlName="histDate"
              readonly
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="minDatenstand">{{ "modules.data-management.serien-daten.upload-list.min-date" | transloco }}</label>

            <div class="input-group date-background-editable">
              <input
                #searchMinDateStatus

                class="form-control dp "
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="minDatenstand"
                name="dp"
                formControlName="minDatenstand"
                ngbDatepicker
                #d="ngbDatepicker"
                readonly
                (click)="removeMinDateStatusFocus()"
              />
              <div class="input-group-append">
                <fa-icon
                  class="dp"
                  icon="calendar-alt"
                  (click)="d.toggle()"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="uploadDate">{{
              "modules.data-management.serien-daten.upload-list.upload-date" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="uploadDate"
              formControlName="uploadDate"
              readonly
            />
          </div>
        </div>
        
      </div>

      <div class="row add-space">

        <div class="col-4">
          <label for="contactPerson">{{
            "modules.data-management.serien-daten.upload-list.contact-person" | transloco
          }}</label>
          <input
            appTrim
            class="form-control small-size"
            id="contactPerson"
            formControlName="contactPerson"
            readonly
          />
        </div>
        
        <div class="col-4">
          <div class="form-group">
            <label for="prio">{{
              "modules.data-management.serien-daten.upload-list.prio" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="prio"
              formControlName="prio"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label for="requirement">{{
              "modules.data-management.serien-daten.upload-list.requirement" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="requirement"
              formControlName="requirement"
            />
          </div>
        </div>
      </div>

      <div class="row form-group add-space">
        <div class="col-12">
          <div class="form-group">
            <label for="comment">{{
              "modules.data-management.table-header.comment" | transloco
            }}</label>
            <textarea
              appTrim
              type="text"
              autocomplete="off"
              rows="4"
              class="form-control small-size"
              id="comment"
              formControlName="comment"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="button-area float-end">
            <div class="displayed">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="cancel()"
              >
                {{ "global.cancel" | transloco }}
              </button>

              <button type="button" class="btn btn-primary" (click)="save()">
                {{ "modules.expert-tool.btn-apply-changes" | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
