import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AttributesVpRate } from '@core/enums/attributes-vp-rate.enum';
import { DiebstahlkennerStatus } from '@core/enums/diebstahlkenner.enum';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { StorageService } from '@core/services/storage.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { ValidationService } from '@core/services/validation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { ControlUnits } from '@shared/models/ControlUnits';
import { VedocData } from '@shared/models/experttool/VedocData';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import { CheckBoxSelect } from '@shared/models/Select';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { SharedSeriesService } from '@core/services/shared-series.service';

import { FcscFwControlUnitsModalComponent } from '../fcsc-fw-control-units-modal/fcsc-fw-control-units-modal.component';
import { FcscFwEditModalComponent } from '../fcsc-fw-edit-modal/fcsc-fw-edit-modal.component';
import { FcscFwVehicleCodesModalComponent } from '../fcsc-fw-vehicle-codes-modal/fcsc-fw-vehicle-codes-modal.component';
import {faCaretDown, faPencil, faReceipt, faSearch} from "@fortawesome/pro-solid-svg-icons";
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';

@Component({
  selector: 'app-fcsc-fw-vedoc-details',
  templateUrl: './fcsc-fw-vedoc-details.component.html',
  styleUrls: ['./fcsc-fw-vedoc-details.component.scss'],
})
export class FcscFwVedocDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('dataTable') dataTable!: DatatableComponent;
  @Input() vedocData!: VedocData;
  @Output() handleSelection = new EventEmitter<any>();
  form!: UntypedFormGroup;
  searchForm!: UntypedFormGroup;
  rows!: ControlUnits[];
  orignalRows!: ControlUnits[];
  sortConfig!: Array<{}>;
  params: GeneralPageInfo | undefined;
  page = new Page();
  paginationDataSetting!: any;
  selected: ControlUnits[] = [];
  orignalSelected: ControlUnits[] = [];
  dataTableSelection = SelectionType.checkbox;
  emptyMessage!: string;
  isVedocSectionCollapsed = true;
  isVedocSubSectionCollapsed = true;
  records!: ControlUnits[];
  searchKeyword = '';
  subscription!: Subscription;
  pageSize!: number;
  selectedSize!: number | string;
  allPageObject!: any;
  detectedBaureihe : any;
  isShadowModelSeries : boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private appService: AppService,
    private storageService: StorageService,
    private notificationService: ErrorHandlerService,
    private validationService: ValidationService,
    private userSettingService: UserSettingService,
    private library: FaIconLibrary,
    private expertToolDataModelService : ExperttoolDataModelingService,
    private sharedSeriesService : SharedSeriesService
  ) { library.addIcons(faReceipt, faPencil, faSearch, faCaretDown) }

  ngOnInit(): void {
    this.configPageInfo();
    this.translateAllPaginationText();
    this.loadStorageData();
    this.createForm();
    this.createSearchForm();
    this.setId();
    this.rows = this.getModeledResponse(this.vedocData.controlUnits);
    this.records = this.loadRecords();
    this.setInitialSelction();
    this.setSelected(false);
    this.deepCopyOrignalRows();
    this.setDisability();
    this.setTranslation();
    this.triggerActions();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.storageService.removeItem(sessionStorageKeys.searchKeyword);
  }

  public loadRecords() {
    const records =
      this.storageService.getData(sessionStorageKeys.vedocRecords) ||
      JSON.parse(JSON.stringify(this.vedocData.controlUnits));
    this.storageService.saveData(sessionStorageKeys.vedocRecords, records);
    this.storageService.saveData(sessionStorageKeys.vedocDataCopy, records);
    return records;
  }

  private translateAllPaginationText() {
    this.translocoService
      .selectTranslate('components.footer.all')
      .subscribe((resp) => {
        this.allPageObject = { label: resp, value: 'all' };
      });
  }

  private triggerActions() {
    this.filterRecords();
  }

  private loadStorageData() {
    const keyword = this.storageService.getData(
      sessionStorageKeys.searchKeyword
    );
    this.searchKeyword = keyword || '';
  }

  private getModeledResponse(response: ControlUnits[]) {
    return response.map((data) => {
      data.swSnrs =
        typeof data.swSnrs === 'object'
          ? data.swSnrs.join().split(',').join(' ')
          : data.swSnrs;
      return data;
    });
  }

  detectBaureihe(baureiheList, extractedSerie) {
    for (const baureihe of baureiheList) {
        if (baureihe.brName === extractedSerie) {
            return baureihe;
        }

        const matchingItem = baureihe.shadowModelList.find(item => item.value === extractedSerie);
        if (matchingItem) {
            return baureihe;
        }
    }
    return undefined;
}

  private createForm(): void {
    const formData =
    this.storageService.getData(sessionStorageKeys.formData) || {};
    let sums_RelevanceStatus : any = ''
    if (this.expertToolDataModelService.baureiheList) {
      const detectedBaureihe = this.detectBaureihe(this.expertToolDataModelService.baureiheList, this.sharedSeriesService.getExtractedSerie(this.vedocData))
      if (detectedBaureihe) {
        sums_RelevanceStatus = detectedBaureihe.sumsRelevanceStatus
      }
    }
    this.form = this.formBuilder.group({
      fin: new UntypedFormControl({ value: this.vedocData.fin, disabled: true }),
      vin: new UntypedFormControl({ value: this.vedocData.vin, disabled: true }),
      ttz: new UntypedFormControl({
        value: formData.ttz || this.vedocData.ttz,
        disabled: true,
      }),
      // sparte: new FormControl(this.vedocData.sparte),
      codes: new UntypedFormControl({
        value: formData.codes || this.vedocData.codes,
        disabled: true,
      }),
      attributes: new UntypedFormControl({
        value: formData.attributes || this.vedocData.attributes,
        disabled: true,
      }),
      status: new UntypedFormControl({
        value: this.getstatusTranslatedDefaultValue(),
        disabled: true,
      }),
      sumsRelevanceStatus :new UntypedFormControl({
        value : this.getSumsRelevanceStatus(sums_RelevanceStatus) || "",
        disabled : true
      })
    });

    this.subscription = this.form.valueChanges.subscribe((formValue) => {
      const storedForm = {
        ttz: formValue.ttz,
        attributes: formValue.attributes,
        codes: formValue.codes,
      };
      this.storageService.saveData(sessionStorageKeys.formData, storedForm);
    });
  }

  private createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      searchField: new UntypedFormControl(this.searchKeyword),
    });
  }

  private setDisability(): void {
    this.rows.map((record) => (record.disabled = true));
    this.orignalRows.map((record) => (record.disabled = true));
  }

  checkRecordsLength() {
    const newHwsnrRec = this.rows?.map((el) => {
      return el?.hwSnr?.split(/(\s+)/).filter((e) => e.trim().length > 0);
    });
    const newFwmbsRec = this.rows?.map((el) => {
      return el?.swSnrs?.split(/(\s+)/).filter((e) => e.trim().length > 0);
    });

    return (
      this.validationService.validateDataTableSingleRecord(newHwsnrRec) ||
      this.validationService.validateDataTableRecord(newFwmbsRec)
    );
  }

  updateHeaderAttribEtn(details: VedocData): void {
    this.form.get('attributes')?.setValue(details.attributes);
    this.form.get('codes')?.setValue(details.codes);
    this.form.get('fin')?.setValue(details.fin);
    this.form.get('vin')?.setValue(details.vin);
    this.form.get('ttz')?.setValue(details.ttz);
    this.form
      .get('status')
      ?.setValue(
        `${this.translocoService.translate(
          DiebstahlkennerStatus[
            details.status as keyof typeof DiebstahlkennerStatus
          ]
        )}`
      );
  }

  // This is called when translation is triggered.
  setTranslation(): void {
    this.appService.languageSubject.subscribe(() => {
      setTimeout(() => {
        this.setstatusInitialValue();
      }, 100);
    });

    this.translocoService
      .selectTranslate('modules.expert-tool.empty-table-sg')
      .subscribe((resp) => {
        this.emptyMessage = resp;
      });
  }

  setstatusInitialValue(): void {
    this.form.get('status')?.setValue(this.getstatusTranslatedDefaultValue());
  }

  getstatusTranslatedDefaultValue(): void {
    return this.translocoService.translate(
      DiebstahlkennerStatus[
        this.vedocData.status as keyof typeof DiebstahlkennerStatus
      ]
    );
  }

  getSumsRelevanceStatus(status:String) : String {
    let value = ''
    this.isShadowModelSeries = true;
    if (status && status != ''){
        value = status === "NOT_RELEVANT" ?  'Not Relevant' :  'Relevant'
        this.isShadowModelSeries = false;
    }
     return value
   }
 

  deepCopyOrignalRows(): void {
    this.orignalRows = JSON.parse(JSON.stringify(this.rows));
  }

  getDeepCopyOriganlRows(): ControlUnits[] {
    return JSON.parse(JSON.stringify(this.orignalRows)) as ControlUnits[];
  }

  setInitialSelction(): void {
    const selected = this.storageService.getData(sessionStorageKeys.selected);
    if (!selected) {
      this.rows.forEach((row) => {
        row.selected = false;
      });
    } else {
      this.rows.forEach((row) => {
        row.selected = selected.find(
          (selectedRow: ControlUnits) => selectedRow.diogName === row.diogName
        );
      });
    }
    this.selectedSize = this.allPageObject.value;
    this.pageSize = this.rows.length;
  }

  clearSearchForm(): void {
    this.searchForm.get('searchField')?.setValue('');
  }

  setId(): void {
    if (this.vedocData.controlUnits === null || !this.vedocData.controlUnits) {
      this.vedocData.controlUnits = [];
    }
    this.vedocData.controlUnits.map((row, index) => (row.id = index + 1));
  }

  onSelect(event: CheckBoxSelect): void {
    if (event && event.selected && event.selected.length) {
      const oldSelected: ControlUnits[] = [...this.selected];
      this.selected = event.selected || [...this.rows];
      let diff: ControlUnits[] = [];
      if (oldSelected.length > this.selected.length) {
        this.selected.forEach((element) => {
          const found = oldSelected.find((sel) => sel.id === element.id);
          if (!found) {
            diff.push(element);
          }
        });
      } else {
        oldSelected;
        this.selected.forEach((element) => {
          oldSelected.forEach((row) => {
            if (JSON.stringify(element) !== JSON.stringify(row)) {
              diff.push(element);
            }
          });
        });
      }
      if (oldSelected.length === this.selected.length) {
        diff = this.selected;
      }
      if (!oldSelected.length && this.selected.length) {
        diff = this.selected;
      }
      if (!diff.length) {
        this.selected = diff;
      }
      const combArray: ControlUnits[] = this.selected.concat(diff);

      this.rows.forEach((element) => {
        let found = false;
        combArray.forEach((row) => {
          if (JSON.stringify(element) === JSON.stringify(row)) {
            found = true;
          }
        });
        element.selected = found;
      });

      this.rows.sort((a, b) => (a.selected === b.selected ? 1 : -1));
      this.replaceOrginal();
      this.resetTable(true);
    }
    if (event && event.selected && event.selected.length === 0) {
      this.rows.forEach((element) => {
        element.selected = false;
      });

      this.replaceOrginal();
      this.selected = [];
      this.resetTable(true);
    }
    this.orignalRows.forEach((row) => {
      if (row.selected) {
        const foundSelected = this.selected.find((sel) => sel.id === row.id);
        if (!foundSelected) {
          this.selected.push(row);
        }
      }
    });

    this.orignalSelected = JSON.parse(JSON.stringify(this.selected));
    this.saveOrignalSelected();
    this.handleSelection.emit();
  }

  saveOrignalSelected() {
    this.storageService.saveData(
      sessionStorageKeys.selected,
      this.orignalSelected
    );
  }

  resetSelected() {
    const selected: ControlUnits[] = [];
    this.rows.forEach((row) => {
      if (row.selected) {
        selected.push(row);
      }
    });
    this.saveOrignalSelected();
  }

  setPageSize(pageSize?: any, setDefualtPage?: boolean) {
    this.page.orderBy = this.dataTable.sorts
      ? this.dataTable.sorts[0].prop
      : 'diogName';
    this.page.orderDir = this.dataTable.sorts
      ? this.dataTable.sorts[0].dir
      : 'asc';
    if (!setDefualtPage) {
      this.dataTable.offset = 0;
    }

    if (pageSize) {
      this.pageSize = pageSize;
      this.selectedSize = pageSize;
    } else {
      this.pageSize = this.rows.length;
      this.selectedSize = 'all';
    }

    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.cuTable,
      this.loadPageParams()
    );
  }

  onSortCallback(sortInfo?: SortInfo) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.cuTable,
      this.loadPageParams()
    );
    this.setSelected(false);
  }

  onPageChange(event?) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.cuTable,
      this.loadPageParams()
    );
    this.appService.resetDatatableScroll();
  }

  filterRecords(): void {
    const searchValue: string = this.searchForm.get('searchField')?.value;
    this.storageService.saveData(sessionStorageKeys.searchKeyword, searchValue);
    const orignalRecords: ControlUnits[] = this.getDeepCopyOriganlRows();
    const filteredRows: ControlUnits[] = orignalRecords.filter((row: any) => {
      return (
        row.diogName.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.swSnrs
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        row.hwSnr.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    this.rows = [...filteredRows];
    this.selected = [];
    filteredRows.forEach((record: any) => {
      if (record.selected) {
        this.selected.push(record);
      }
    });
  }

  onDoubleClick(key: string): void {
    this.form.get(key)?.enable();
  }

  openModal(key: string): void {
    const modalRef: NgbModalRef = this.modalService.open(
      FcscFwEditModalComponent,
      {
        size: 'lg',
        windowClass: 'edit-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.expert-tool.manual-edit'
    );
    modalRef.componentInstance.subText =
      key === 'codes'
        ? this.translocoService.translate(
            'modules.expert-tool.modal-edit-codes-subtitle'
          )
        : this.translocoService.translate(
            'modules.expert-tool.modal-edit-attributes-subtitle'
          );
    modalRef.componentInstance.fieldText =
      key === 'codes'
        ? this.translocoService.translate('modules.expert-tool.codes')
        : this.translocoService.translate('modules.expert-tool.attribute');
    modalRef.componentInstance.data = this.form.get(key)?.value;
    modalRef.result.then((result) => {
      if (result && result.key === 'OK') {
        this.form.get(key)?.setValue(result.data);
        if (key === 'codes') {
          this.vedocData.codes = result.data && result.data.split(',');
        } else {
          this.vedocData.attributes = result.data && result.data.split('/n');
        }

        this.updateVedocDataStore();
      }
    });
  }

  openVehicleCodeModal(key: string): void {
    const codes = this.form.get('codes')?.value;
    const modalRef: NgbModalRef = this.modalService.open(
      FcscFwVehicleCodesModalComponent,
      {
        size: 'lg',
        windowClass: 'details-modal override',
        backdrop: 'static',
        keyboard: false,
      }
    );

    modalRef.componentInstance.fin = this.form.get('fin')?.value
      ? this.form.get('fin')?.value
      : this.form.get('vin')?.value;
    modalRef.componentInstance.codeList = Array.isArray(codes)
      ? codes
      : codes.split(',');
    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.INSERT_SUCCESSFUL) {
        const selected = result.selected;
        const filterSelected: string[] = selected.filter((code) => code);
        const selectedCodes: string[] = [...new Set(filterSelected)];
        this.form.get('codes')?.setValue(selectedCodes);
        this.vedocData.codes = selectedCodes;
        this.updateVedocDataStore();
      }
    });
  }

  onFocusOut(key: string): void {
    this.form.get(key)?.disable();
    this.vedocData.ttz = this.form.get('ttz')?.value;
    this.updateVedocDataStore();
  }

  updateDatatable(event: ControlUnits[][]): void {
    this.checkEdit(event[0]);
  }

  openControlUnitsModal(data?: ControlUnits, index?: number): void {
    const modalRef: NgbModalRef = this.modalService.open(
      FcscFwControlUnitsModalComponent,
      {
        size: 'lg',
        windowClass: 'cont-units-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    modalRef.componentInstance.title = data
      ? this.translocoService.translate('modules.expert-tool.manual-edit')
      : this.translocoService.translate('modules.expert-tool.insert-new-line');
    modalRef.componentInstance.subText = data
      ? this.translocoService.translate(
          'modules.expert-tool.modal-edit-sg-subtitle'
        )
      : this.translocoService.translate(
          'modules.expert-tool.modal-new-line-sg-subtitle'
        );
    modalRef.componentInstance.dataList = this.rows;
    modalRef.componentInstance.buttonText = data
      ? this.translocoService.translate('modules.expert-tool.btn-apply-changes')
      : this.translocoService.translate('modules.expert-tool.insert-new-line');
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result && result.index !== null && result.index !== undefined) {
        if (result.record.diogName) {
          const foundRec = this.rows.find(
            (record) => record.diogName === result.record.diogName
          );
          if (foundRec && result.checkFlag) {
            this.notificationService.showError(
              '',
              this.translocoService.translate(
                'modules.expert-tool.diog-name-valdation'
              )
            );
            return;
          }
        } else {
          this.notificationService.showError(
            '',
            this.translocoService.translate(
              'modules.expert-tool.diog-name-empty-valdation'
            )
          );
          return;
        }
        const idx: number = this.rows.findIndex(
          (rowObj: ControlUnits) => rowObj.id === result.record.id
        );
        const row: ControlUnits = this.rows[idx];
        result.record.id = row?.id;
        result.record.disabled = true;
        this.rows[idx] = result.record;
        const currentRow: ControlUnits = this.rows[idx];
        currentRow.diogName = result.record.diogName;
        currentRow.swSnrs = result.record.swSnrs;
        currentRow.hwSnr = result.record.hwSnr;
        currentRow.attributes = result.record.attributes;
        currentRow.visibleCheck =
          result.record.attributes !==
          this.translocoService.translate(AttributesVpRate.BY_PROIRITY);
        this.replaceOrginal();
        this.setSelected(false);
      } else {
        if (result) {
          if (result.record.diogName) {
            const foundRec = this.rows.find(
              (record) => record.diogName === result.record.diogName
            );
            if (foundRec) {
              this.notificationService.showError(
                '',
                this.translocoService.translate(
                  'modules.expert-tool.diog-name-valdation'
                )
              );
              return;
            }
          } else {
            this.notificationService.showError(
              '',
              this.translocoService.translate(
                'modules.expert-tool.diog-name-empty-valdation'
              )
            );
            return;
          }
          result.record.id = this.orignalRows.length + 1;
          result.record.attributes = result.record.attributes;
          result.record.visibleCheck =
            result.record.attributes !==
            this.translocoService.translate(AttributesVpRate.BY_PROIRITY);
          this.rows.push(result.record);
          this.orignalRows.push(result.record);
          const newRecord: ControlUnits = {
            id: result.record.id,
            diogName: result.record.diogName,
            swSnrs: result.record.swSnrs,
            hwSnr: result.record.hwSnr,
            disabled: result.record.disabled,
            selected: result.record.selected,
            attributes: result.record.attributes,
            visibleCheck:
              result.record.attributes !==
              this.translocoService.translate(AttributesVpRate.BY_PROIRITY),
          };
          this.records.push(JSON.parse(JSON.stringify(newRecord)));
          this.orignalSelected.push(newRecord);
          this.updateVedocDataStore();
          this.storageService.saveData(
            sessionStorageKeys.vedocRecords,
            this.records
          );
          this.setSelected(false);
        }
      }
      this.vedocData.controlUnits = this.orignalRows;
      Object.assign({}, this.vedocData);
      this.updateVedocDataStore();
      this.resetTable();
      this.handleSelection.emit();
    });
  }

  resetTable(flag?) {
    const rows = this.rows;
    this.rows = [];
    setTimeout(() => {
      this.rows = rows;
      if (!flag) {
        const { size } = this.paginationDataSetting || {};
        const initialSize =
          (size === 'all' ? null : size) ||
          (this.selectedSize === 'all' ? null : this.pageSize);
        this.setPageSize(initialSize, true);
      }
    }, 50);
  }

  checkEdit(rows: ControlUnits[]): void {
    rows.forEach((row) => {
      this.orignalRows.forEach((orignalRow) => {
        if (row.id === orignalRow.id) {
          row.disabled = JSON.stringify(orignalRow) === JSON.stringify(row);
        }
      });
    });
    this.replaceOrginal(true);
    this.replaceOrginalSelected();
    this.vedocData.controlUnits = this.orignalRows;
    Object.assign({}, this.vedocData);
    this.updateVedocDataStore();
  }

  setSelected(useStore: boolean = true): void {
    this.selected = [];
    let selectedRows: ControlUnits[] = [];
    if (useStore) {
      const data =
        this.storageService.getData(sessionStorageKeys.selected) || [];
      data.forEach((element) => {
        const dt: ControlUnits | undefined = this.rows.find(
          (row) => row.diogName === element.diogName
        );
        if (dt) {
          selectedRows.push(dt);
        }
      });
      this.selected = selectedRows;
      this.storageService.saveData(sessionStorageKeys.selected, this.selected);
    } else {
      const rows = this.rows;
      selectedRows = rows.filter((row) => row.selected);
      this.selected = selectedRows;
      this.replaceOrginalSelected();
      this.saveOrignalSelected();
    }
  }

  replaceOrginalSelected(): void {
    if (!this.orignalSelected.length) {
      this.orignalSelected = JSON.parse(JSON.stringify(this.selected));
    }
    this.orignalSelected.forEach((orow) => {
      this.selected.forEach((row) => {
        if (row.id === orow.id) {
          orow.diogName = row.diogName;
          orow.disabled = row.disabled;
          orow.swSnrs = row.swSnrs;
          orow.hwSnr = row.hwSnr;
          orow.selected = row.selected;
          orow.visibleCheck = row.visibleCheck;
          orow.attributes = row.attributes;
        }
      });
    });
    this.saveOrignalSelected();
  }

  replaceOrginal(flag?): void {
    this.orignalRows.forEach((orow) => {
      this.rows.forEach((row) => {
        if (row.id === orow.id) {
          orow.diogName = row.diogName;
          orow.disabled = row.disabled;
          orow.swSnrs = row.swSnrs;
          orow.hwSnr = row.hwSnr;
          orow.selected = row.selected;
          orow.visibleCheck = row.visibleCheck;
          orow.attributes = row.attributes;
        }
      });
    });
    if (!flag) {
      this.resetSelected();
    }

    this.updateVedocDataStore();
  }

  public updateVedocDataStore() {
    const data = { ...this.vedocData };
    data.controlUnits = this.orignalRows.map((row) => {
      return { ...row, swSnrs: row.swSnrs?.split(',') };
    });
    this.storageService.saveData(sessionStorageKeys.vedocData, data);
  }

  private async configPageInfo() {
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.experttool.cuTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;

    // Sort
    const sortData = sort || 'diogName,asc';
    const sortArray = sortData?.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Size
    this.pageSize =
      (size === 'all' ? null : size) ||
      (this.selectedSize === 'all' ? null : this.pageSize);

    // Reset table
    this.resetTable();
  }

  private loadPageParams(): any {
    this.page.orderBy = this.dataTable.sorts
      ? this.dataTable.sorts[0].prop
      : 'diogName';
    this.page.orderDir = this.dataTable.sorts
      ? this.dataTable.sorts[0].dir
      : 'asc';
    this.params = {
      size: this.selectedSize,
      sort: `${this.page.orderBy},${this.page.orderDir}`,
    };

    return this.params;
  }
}
