<div class="outer-container stretch">
  <div>
    <span class="result-title">
      {{ "modules.admin-section.testautomation.test-result.title" | transloco }}
    </span>
    <p class="subtitle">{{
        "modules.admin-section.testautomation.test-result.subtitle" | transloco
      }} {{ recentTestRun.timeStamp | date: "dd.MM.yy/HH:mm:ss" }}</p>
    <div class="test-result-section">
      <div class="test-result" *ngIf="recentTestRun.successfulTestCases > 0">
        <label class="label">
          <fa-icon
            [style.color]="StatusColor.GREEN"
            icon="circle"
          ></fa-icon>
          {{ recentTestRun.successfulTestCases }} {{ "modules.admin-section.testautomation.test-result.succeeded"  | transloco }}
        </label>
      </div>
      <div class="test-result" *ngIf="recentTestRun.unsuccessfulTestCases > 0">
        <label class="label">
          <fa-icon
            [style.color]="StatusColor.RED"
            icon="circle"
          ></fa-icon>
          {{ recentTestRun.unsuccessfulTestCases }} {{ "modules.admin-section.testautomation.test-result.failed"  | transloco }}
        </label>
      </div>
    </div>
  </div>
</div>
