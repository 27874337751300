<div class="outer-container">
  <div>
    <span class="title">{{
      "modules.expert-tool.vedoc-title" | transloco
    }}</span>
    <span
      class="caret-spacer"
      (click)="isVedocSectionCollapsed = !isVedocSectionCollapsed; resetTable()"
    >
      <fa-icon
        *ngIf="isVedocSectionCollapsed"
        icon="caret-down"
        size="lg"
      ></fa-icon>
      <fa-icon
        *ngIf="!isVedocSectionCollapsed"
        icon="caret-up"
        size="lg"
      ></fa-icon>
    </span>
  </div>
  <div [ngbCollapse]="!isVedocSectionCollapsed">
    <form [formGroup]="form">
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="fin">FIN</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control normal-size"
              id="fin"
              readonly
              formControlName="fin"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="vin">VIN</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control normal-size"
              id="vin"
              readonly
              formControlName="vin"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group double-wrapper-parent">
            <div
              *ngIf="!form.get('ttz')?.enabled"
              class="double-wrapper-input"
              (click)="onDoubleClick('ttz')"
            ></div>
            <label for="ttz">TTZ</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size ttz enable-opacity"
              id="ttz"
              (blur)="onFocusOut('ttz')"
              formControlName="ttz"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="status">{{
              "modules.expert-tool.theft-connoisseur" | transloco
            }}</label>
            <input
              type="text"
              autocomplete="off"
              readonly
              class="form-control normal-size"
              id="status"
              formControlName="status"
            />
          </div>
        </div>

        <div *ngIf="!isShadowModelSeries" class="col" >
            <div class="form-group">
              <label for="sumsCheck">{{
                "modules.expert-tool.sums-relevance" | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                readonly
                class="form-control normal-size"
                id="sumsRelevanceStatus"
                formControlName="sumsRelevanceStatus"
              />
            </div>
          </div>
      </div>

      <div class="row row-cols-auto second">
        <div class="col-6">
          <div class="form-group double-wrapper-parent">
            <div
              *ngIf="!form.get('codes')?.enabled"
              class="double-wrapper-textarea"
              (dblclick)="openModal('codes')"
            ></div>
            <label for="codes">{{
              "modules.expert-tool.codes" | transloco
            }}</label>
            <fa-icon
              class="edit"
              icon="receipt"
              (click)="openVehicleCodeModal('codes')"
            ></fa-icon>

            <fa-icon
              class="edit"
              icon="pencil"
              (click)="openModal('codes')"
            ></fa-icon>
            <textarea
              class="form-control textarea-custom"
              id="codes"
              rows="5"
              formControlName="codes"
              (blur)="onFocusOut('codes')"
            ></textarea>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group double-wrapper-parent">
            <div
              *ngIf="!form.get('attributes')?.enabled"
              class="double-wrapper double-wrapper-textarea"
              (dblclick)="openModal('attributes')"
            ></div>
            <label for="attributes">{{
              "modules.expert-tool.attribute" | transloco
            }}</label>
            <fa-icon
              class="edit"
              icon="pencil"
              (click)="openModal('attributes')"
            ></fa-icon>
            <textarea
              class="form-control textarea-custom"
              id="attributes"
              rows="5"
              formControlName="attributes"
            ></textarea>
          </div>
        </div>
      </div>
    </form>

    <div class="row control-units-table">
      <div class="col-12">
        <div>
          <div class="control-units col-2">
            <span class="sub-title">{{
              "modules.expert-tool.control-units" | transloco
            }}</span>
            <span class="caret-spacer-subsection" (click)="resetTable()">
              <fa-icon
                *ngIf="isVedocSubSectionCollapsed"
                icon="caret-down"
                size="lg"
                (click)="
                  isVedocSubSectionCollapsed = !isVedocSubSectionCollapsed
                "
              ></fa-icon>
              <fa-icon
                *ngIf="!isVedocSubSectionCollapsed"
                icon="caret-up"
                size="lg"
                (click)="
                  isVedocSubSectionCollapsed = !isVedocSubSectionCollapsed
                "
              ></fa-icon>
            </span>
          </div>
          <div [ngbCollapse]="!isVedocSubSectionCollapsed">
            <div class="row">
              <div class="col-6">
                <form [formGroup]="searchForm">
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control search"
                    formControlName="searchField"
                    placeholder="{{
                      'modules.expert-tool.search-placeholder' | transloco
                    }}"
                    (keyup)="filterRecords()"
                  />
                  <span class="search-icon">
                    <fa-icon icon="search" size="sm"></fa-icon>
                  </span>
                </form>
              </div>

              <div class="col-6 round-button-container">
                <icon-button
                  [visible]="true"
                  [buttonType]="'Page'"
                  toolTipDescription="{{
                    'modules.expert-tool.insert-new-line' | transloco
                  }}"
                  [iconSelector]="'plus'"
                  (click)="openControlUnitsModal()"
                ></icon-button>
              </div>
            </div>

            <div *ngIf="isVedocSubSectionCollapsed">
              <ngx-datatable
                #dataTable
                class="material datatable-scrollable"
                [columnMode]="'flex'"
                [headerHeight]="56"
                rowHeight="auto"
                [rows]="rows"
                [sorts]="sortConfig"
                [selected]="selected"
                (page)="onPageChange($event)"
                [messages]="{ emptyMessage: emptyMessage }"
                [selectionType]="dataTableSelection"
                [selectAllRowsOnPage]="false"
                (select)="onSelect($event)"
                (sort)="onSortCallback($event)"
                [footerHeight]="56"
                [limit]="pageSize"
              >
                <ngx-datatable-column
                  [cellClass]="'text-center'"
                  [headerClass]="'checkbox-sort'"
                  name=""
                  prop="selected"
                  [flexGrow]="0"
                  [sortable]="true"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                  [width]="50"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn"
                  >
                    <label class="checkbox-container">
                      <input
                        type="checkbox"
                        checked="checked"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                      />
                      <span
                        class="checkmark"
                        [ngClass]="{
                          'border-red':
                            this.selected && this.selected.length === 0
                        }"
                      ></span>
                    </label>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <label class="checkbox-container">
                      <input
                        type="checkbox"
                        [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-center'"
                  name="{{ 'modules.expert-tool.attribute' | transloco }}"
                  [flexGrow]="1.25"
                  [sortable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <icon-button
                      [visible]="row.visibleCheck || false"
                      toolTipDescription="{{
                        'modules.expert-tool.tooltip.edit-row' | transloco
                      }}"
                      [iconSelector]="'check'"
                      (click)="openControlUnitsModal(row, rowIndex)"
                    ></icon-button>
                  </ng-template>
                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    ngx-datatable-header-template
                  >
                    <span
                      ngbTooltip="{{
                        'modules.expert-tool.table-header.attributes'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'modules.expert-tool.diog-name' | transloco }}"
                  prop="diogName"
                  [flexGrow]="2"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                  >
                    <inline-editing-cell
                      (updateDatatable)="updateDatatable($event)"
                      [primaryKey]="'id'"
                      [secondaryKey]="'diogName'"
                      [row]="row"
                      [cellPropertyName]="'diogName'"
                      [cellPropertyValue]="value"
                      [data]="rows"
                      [orignalData]="records"
                    >
                    </inline-editing-cell>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort datatable-header-cell-label"
                      ngbTooltip="{{
                        'modules.expert-tool.table-header.diog-name'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{ 'modules.expert-tool.hwsnr' | transloco }}"
                  prop="hwSnr"
                  [flexGrow]="2"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                  >
                    <inline-editing-cell
                      (updateDatatable)="updateDatatable($event)"
                      [row]="row"
                      [primaryKey]="'id'"
                      [cellPropertyName]="'hwSnr'"
                      [cellPropertyValue]="value"
                      [data]="rows"
                      [orignalData]="records"
                      [validationSingleHighlightKey]="'hwSnr'"
                    >
                    </inline-editing-cell>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort datatable-header-cell-label"
                      ngbTooltip="{{
                        'modules.expert-tool.table-header.hwsnr'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'modules.expert-tool.fw-on-cu' | transloco }}"
                  prop="swSnrs"
                  [flexGrow]="7"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                  >
                    <inline-editing-cell
                      (updateDatatable)="updateDatatable($event)"
                      [primaryKey]="'id'"
                      [row]="row"
                      [cellPropertyName]="'swSnrs'"
                      [cellPropertyValue]="value"
                      [data]="rows"
                      [orignalData]="records"
                      [longText]="true"
                      [validationHighlightKey]="'swSnrs'"
                    >
                    </inline-editing-cell>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort datatable-header-cell-label"
                      ngbTooltip="{{
                        'modules.expert-tool.table-header.fw-on-cu'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-center'"
                  name="{{ 'global.actions' | transloco }}"
                  [flexGrow]="3"
                  [sortable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <icon-button
                      [visible]="true"
                      toolTipDescription="{{
                        'modules.expert-tool.tooltip.edit-row' | transloco
                      }}"
                      [iconSelector]="'pencil'"
                      (click)="openControlUnitsModal(row, rowIndex)"
                    ></icon-button>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    ngx-datatable-header-template
                  >
                    <span
                      ngbTooltip="{{
                        'global.actions' | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer *ngIf="true">
                  <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-offset="offset"
                    let-curPage="curPage"
                  >
                    <div class="col-7">
                      <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        (change)="dataTable.onFooterPage($event)"
                      >
                      </datatable-pager>
                    </div>

                    <div class="col-5 page-result-section client-side">
                      <span class="float-end">
                        <span class="result-text">{{
                          "components.footer.result-per-page" | transloco
                        }}</span>
                        <span
                          class="result-value"
                          (click)="setPageSize(10)"
                          [ngClass]="{
                            active:
                              pageSize === 10 &&
                              selectedSize !== allPageObject.value
                          }"
                        >
                          10 </span
                        >|
                        <span
                          class="result-value"
                          (click)="setPageSize(30)"
                          [ngClass]="{
                            active:
                              pageSize === 30 &&
                              selectedSize !== allPageObject.value
                          }"
                        >
                          30 </span
                        >|
                        <span
                          class="result-value"
                          (click)="setPageSize(100)"
                          [ngClass]="{
                            active:
                              pageSize === 100 &&
                              selectedSize !== allPageObject.value
                          }"
                        >
                          100 </span
                        >|
                        <span
                          class="result-value margin-override"
                          (click)="setPageSize()"
                          [ngClass]="{
                            active: selectedSize === allPageObject.value
                          }"
                          >{{ allPageObject.label }}</span
                        >
                        <span>
                          {{ "components.footer.files" | transloco }}:
                          {{
                            rowCount === 0
                              ? rowCount
                              : (curPage - 1) * pageSize + 1
                          }}
                          -
                          {{
                            curPage * pageSize > rowCount
                              ? rowCount
                              : curPage * pageSize
                          }}
                        </span>
                        <span>
                          {{ "components.footer.of" | transloco }}
                          {{ rowCount }}</span
                        >
                      </span>
                    </div>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="this.selected && this.selected.length === 0" class="mt-3">
    <app-upload-duration
      [isError]="true"
      [translationKey]="'modules.expert-tool.ecu-not-selected'"
    ></app-upload-duration>
  </div>

  <div *ngIf="checkRecordsLength()" class="mt-3">
    <app-upload-duration
      [isError]="true"
      [translationKey]="'global.validation.table-cell-10'"
    ></app-upload-duration>
  </div>
</div>
