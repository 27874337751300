export const paths = {
  fcsc: {
    root: 'fcs-c',
    dashboard: 'dashboard',
    uploadtool: {
      root: 'uploadtool',
      test: 'test',
      vp5Test: 'test/vp5-test',
      vorab: 'vorab',
      serien: 'serien',
      SerienBDP: 'serien/serien-bdp',
      SerienUploadList: 'serien/upload-list',
    },
    experttool: {
      root: 'experttool',
      flashware: 'flashware',
      logHistory: 'log-history',
    },
    admin: {
      root: 'admin',
      seriesConfig: 'series-config',
      codeList: 'code-list',
      vpVersions: 'vp-versions',
      notificationManagement: 'notification-management',
      fsfLogs: 'fsf-logs',
      testautomation: 'testautomation',
    },
  },
  common: {
    home: 'home',
    notfound: '404',
    forbidden: 'forbidden',
  },
};
export const apiPaths = {
  fcscDatamanager: 'data-manager',
  fcscSteuerung: 'steuerung',
  fcscBerechnung: 'fw-calculation',
  fcscWorkshopBerechnung: 'flashwarecalculation',
  fcscVedocGateway: 'vedoc-gateway',
  fcscCryptoService: 'crypto-service',
  fcscS3Service: 's3-service',
  fcscUserManual: 'user-manual',
  fcscCalculationArchive: 'calculation-archive',
  fcscSmokeTestError: 'smoke-test-error-response',
  fcscVp5Tags: 'vp5-tags',
  fcscSubscriptionManager: 'subscription-manager',
  fcscTestautomation: 'test-manager'
};
