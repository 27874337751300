<div *ngIf="(showDiv.current || showImport.current)">
  <div class="modal-header">
    <span class="modal-title">{{ title }}</span>
  </div>

  <div class="modal-sub-text">{{ subText }}</div>

  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row form-group">
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="SG_DiogName">{{
                    "modules.expert-tool.diog-name" | transloco
                  }}</label>
                <input
                  appTrim
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="SG_DiogName"
                  formControlName="SG_DiogName"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label for="SG_REFNR">{{
                    "modules.expert-tool.hwsnr" | transloco
                  }}</label>
                <input
                  appTrim
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="SG_REFNR"
                  formControlName="SG_REFNR"
                  [class.is-invalid]="isInputInvalid('SG_REFNR')"
                />
              </div>
            </div>
          </div>

          <div class="row add-space">
            <div class="col-12">
              <label for="attributesVpRate">{{
                  "modules.expert-tool.attributes-label" | transloco
                }}</label>
              <textarea
                appTrim
                class="form-control small-size"
                id="attributesVpRate"
                formControlName="attributes"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="col-6">
          <label for="FW_KETTE">{{
              "modules.expert-tool.result.labels.etkette" | transloco
            }}</label>
          <textarea
            appTrim
            class="form-control small-size"
            id="FW_KETTE"
            formControlName="FW_KETTE"
            rows="7"
          ></textarea>
        </div>
      </div>

      <div class="row form-group add-space">
        <div class="col-3">
          <div class="form-group">
            <label for="ECU_Refer">{{
                "modules.expert-tool.result.labels.ecu-ref-sg" | transloco
              }}</label>
            <textarea
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="ECU_Refer"
              formControlName="ECU_Refer"
            ></textarea>
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="VP_Methode">{{
                "modules.expert-tool.result.labels.vp" | transloco
              }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="VP_Methode"
              formControlName="VP_Methode"
              readonly
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="Flashbedarf">{{
                "modules.expert-tool.result.datatable.flash-requirement"
                  | transloco
              }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="Flashbedarf"
              formControlName="Flashbedarf"
              readonly
            />
          </div>
        </div>
      </div>
    </form>

    <div class="row control-units-table">
      <!-- 1st table -->
      <div class="col-3">
        <div>
          <div class="row add-space">
            <div class="col-6 round-button-container">
              <span class="sub-title">{{
                  "modules.expert-tool.fw-on-cu" | transloco
                }}</span>
              <icon-button
                [visible]="true"
                [buttonType]="'Page'"
                toolTipDescription="{{
                  'modules.expert-tool.copy-records' | transloco
                }}"
                [iconSelector]="'share-square'"
                [disabled]="fwmbsRows === undefined || (!fwmbsRows.length)" 
                (click)="copyFwmbsRows()"
              ></icon-button>
            </div>
          </div>

          <div class="data-table">
            <ngx-datatable
              #dataTable
              class="material datatable-scrollable"
              [columnMode]="'flex'"
              [headerHeight]="56"
              rowHeight="auto"
              [rows]="fwmbsRows"
              [selectionType]="dataTableSelection"
              [messages]="{
                emptyMessage:
                  'modules.expert-tool.empty-table-result-modal' | transloco
              }"
            >
              <ngx-datatable-column
                name="{{ 'modules.expert-tool.fw-on-cu' | transloco }}"
                prop="FW_MBS"
                [flexGrow]="6"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateFwmbsDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_DiogName'"
                    [row]="row"
                    [cellPropertyName]="'FW_MBS'"
                    [cellPropertyValue]="value"
                    [data]="fwmbsRows"
                    [orignalData]="orignalFwmbsRows"
                    [validationHighlightKey]="'FW_MBS'"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.table-header.fw-on-cu'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>

      <!--   2nd table -->

      <div class="col-9">
        <div>
          <div class="result"></div>
          <div class="row add-space">
            <div class="col-6 round-button-container">
              <span class="sub-title">{{
                  "modules.expert-tool.result.labels.det-fw-title" | transloco
                }}</span>
              <icon-button
                [visible]="true"
                [buttonType]="'Page'"
                toolTipDescription="{{
                  'modules.expert-tool.insert-new-line' | transloco
                }}"
                [iconSelector]="'plus'"
                (click)="openAddNewRecordView()"
              ></icon-button>
              <icon-button
                [visible]="true"
                [buttonType]="'Page'"
                toolTipDescription="{{
                  'modules.expert-tool.mutli-import' | transloco
                }}"
                [iconSelector]="'layer-plus'"
                (click)="openMultiImportRecordView()"
              ></icon-button>
            </div>
          </div>


          <div class="data-table result-table">
            <ngx-datatable
              #dataTable
              class="material datatable-scrollable"
              [columnMode]="'flex'"
              [headerHeight]="56"
              rowHeight="auto"
              [rows]="determinedRows"
              [selectionType]="dataTableSelection"
              [messages]="{
                emptyMessage:
                  'modules.expert-tool.empty-table-result-modal' | transloco
              }"
              [selectionType]="dataTableSelection"
              [selectAllRowsOnPage]="false"
              [selected]="selected"
              (select)="onSelect($event)"
            >
              <ngx-datatable-column
                [cellClass]="'text-center'"
                name="{{
                  'modules.expert-tool.result.labels.flash-selection'
                    | transloco
                }}"
                prop="selected"
                [width]="25"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn"
                  let-row="row"
                  let-rowIndex="rowIndex"
                >
                  <label class="checkbox-container">
                    <input
                      type="checkbox"
                      [checked]="isSelected"
                      (change)="onCheckboxChangeFn($event)"
                    />
                    <span
                      class="checkmark"
                      ngbTooltip="{{
                        'modules.expert-tool.result.labels.flash-selection'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                    ></span>
                  </label>
                </ng-template>

                <ng-template
                  ngx-datatable-header-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-allRowsSelected="allRowsSelected"
                  let-selectFn="selectFn"
                >
                  <label class="checkbox-container">
                    <input
                      type="checkbox"
                      checked="checked"
                      [checked]="allRowsSelected"
                      (change)="selectFn(!allRowsSelected)"
                    />
                    <span
                      class="checkmark"
                      ngbTooltip="{{
                        'modules.expert-tool.result.labels.flash-selection'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                    ></span>
                  </label>
                </ng-template>

                <ng-template
                  ngx-datatable-cell-template
                  let-value="value"
                  let-isSelected="isSelected"
                  let-onCheckboxChangeFn="onCheckboxChangeFn"
                >
                  <label class="checkbox-container">
                    <input
                      type="checkbox"
                      [checked]="isSelected"
                      (change)="onCheckboxChangeFn($event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.flashware' | transloco
                }}"
                prop="SG_FITTINGFLASHSW"
                [flexGrow]="6"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'SG_FITTINGFLASHSW'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [longText]="true"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.flashware'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.prio' | transloco
                }}"
                prop="SG_FITTINGFLASHSW_PRIO"
                [flexGrow]="3"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'SG_FITTINGFLASHSW_PRIO'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.prio'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.size' | transloco
                }}"
                prop="SG_FITTINGFLASHSW_SIZE"
                [flexGrow]="5"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'SG_FITTINGFLASHSW_SIZE'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [longText]="true"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.size'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <!-- prop="SG_FITTING_FWINFO" -->

              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.type' | transloco
                }}"
                prop="type"
                [flexGrow]="3"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'type'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.type'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <!-- prop="SG_NEWFLASHSW_FLASHWEG" -->
              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.flash-way' | transloco
                }}"
                prop="flashweg"
                [flexGrow]="4"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'flashweg'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.flash-way'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{
                  'modules.expert-tool.result.labels.latebound' | transloco
                }}"
                prop="latebound"
                [flexGrow]="9"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                >
                  <inline-editing-cell
                    (updateDatatable)="updateDeterminedDatatable($event)"
                    [primaryKey]="'id'"
                    [secondaryKey]="'SG_FITTINGFLASHSW'"
                    [row]="row"
                    [cellPropertyName]="'latebound'"
                    [cellPropertyValue]="value"
                    [data]="determinedRows"
                    [orignalData]="orignalDeterminedRows"
                    [longText]="true"
                    [disabled]="true"
                  >
                  </inline-editing-cell>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.expert-tool.result.labels.latebound'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [cellClass]="'text-center'"
                name="{{ 'global.actions' | transloco }}"
                [flexGrow]="6"
                [sortable]="false"
                [resizeable]="false"
              >
                <ng-template
                  let-rowIndex="rowIndex"
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <icon-button
                    [visible]="true"
                    toolTipDescription="{{
                      'modules.expert-tool.tooltip.edit-row' | transloco
                    }}"
                    [iconSelector]="'pencil'"
                    (click)="openEditRecordView(row, rowIndex)"
                  ></icon-button>

                  <icon-button
                    [visible]="true"
                    toolTipDescription="{{
                      'modules.expert-tool.tooltip.delete-row' | transloco
                    }}"
                    [iconSelector]="'trash'"
                    (click)="deleteFlashRecord(row)"
                  ></icon-button>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  ngx-datatable-header-template
                >
                  <span
                    ngbTooltip="{{
                      'global.actions' | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="form.invalid" class="mt-3">
      <app-upload-duration
        [isError]="true"
        [translationKey]="'global.validation.input-length-10'"
      ></app-upload-duration>
    </div>

    <div *ngIf="checkFwmbsRecordsLength()" class="mt-3">
      <app-upload-duration
        [isError]="true"
        [translationKey]="'global.validation.table-cell-10'"
      ></app-upload-duration>
    </div>
  </div>

  <div class="modal-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="button-area float-start">
            <div class="displayed">
              <button
                *ngIf=" (showDiv.next || showImport.next)"
                [ngStyle]="{ display: (showDiv.next ? 'display' : 'none' || showImport.next ? 'display': 'none')}"
                type="button"
                class="btn btn-secondary back"
                (click)="backToFirstView()"
              >
                {{ "global.back" | transloco }}
              </button>
            </div>
          </div>

          <div class="button-area float-end">
            <div class="displayed">
              <button
                *ngIf="(showDiv.current || showImport.current)"
                [ngStyle]="{ display: (showDiv.current ? 'display' : 'none' || showImport.current ? 'display': 'none')}"
                type="button"
                class="btn btn-secondary"
                (click)="cancel()"
              >
                {{ "global.cancel" | transloco }}
              </button>

              <button
                *ngIf="(showDiv.current || showImport.current)"
                [ngStyle]="{ display: (showDiv.current ? 'display' : 'none' || showImport.current ? 'display': 'none') }"
                type="button"
                class="btn btn-primary"
                (click)="save()"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showDiv.next">
  <app-fcsc-determined-flashware-insert
    [showDiv]="showDiv"
    (sendDataToFirstView)="eventFromSecondView($event)"
    [data]="dataFromChild"
  >
  </app-fcsc-determined-flashware-insert>
</div>

<div *ngIf="showImport.next">
  <app-fcsc-flashware-multiimport
    [showImport]="showImport"
    (sendMultiImportData)="eventFromSecondView($event)"
  >
  </app-fcsc-flashware-multiimport>
</div>
