<div class="modal-header">
  <span class="modal-title">{{
    "components.header.modal.entitlements" | transloco
  }}</span>
  <div (click)="activeModal.dismiss()">
    <fa-icon class="close-icon" icon="times"></fa-icon>
  </div>
</div>

<div class="modal-header">
  <span class="col">{{
    "components.header.modal.entitlements-sub-title" | transloco
  }}</span>

  <div class="col round-button-container">
    <icon-button
      [visible]="true"
      [buttonType]="'Page'"
      toolTipDescription="{{
        'components.header.modal.entitlements-btn-placeholder' | transloco
      }}"
      [iconSelector]="'copy'"
      (click)="copyEntitlements(entitlementsList)"
    ></icon-button>
  </div>
</div>

<ngx-datatable
        #dataTable
        class="material datatable-scrollable"
        [columnMode]="'flex'"
        [headerHeight]="56"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="userEntitlements"
      >
        <ngx-datatable-column
            [cellClass]="'text-right'"
            name="Entitlements"
            [sortable]="false"
            [flexGrow]="3"
            [resizeable]="false"
            prop="entitlementName"
          >
          </ngx-datatable-column>
        </ngx-datatable>
