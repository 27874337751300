<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div *appRequiredEntitlements="tableListEntitlement" class="outer-container">
  <div id="tabs" #tabs>
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a 
          class="nav-link active"
          [ngClass]="{ 'active active-tab':activeTab==='uploadList'}"
          (click)="switch('uploadList')"
          data-toggle="tab"
        >
          {{ "global.series-upload-list" | transloco }}
        </a>
      </li>
      <li class="nav-item">
        <a 
          class="nav-link" 
          [ngClass]="{ 'active active-tab':activeTab==='additionalInfo'}" 
          data-toggle="tab"
          (click)="switch('additionalInfo')"
        >
        {{ "global.additional-info" | transloco }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane"
        #uploadList 
        id="uploadList"
        [ngClass]="{ 'active':activeTab==='uploadList'}"
      >
        <div class="tab-div">
          {{ "global.productive-from" | transloco }}
          &nbsp;
          <span (click)="switchSort()">
            <fa-icon
              icon="right-long"
              size="sm"
              class="sort-icon"
              *ngIf="sortByAsc"
            ></fa-icon>
            <fa-icon
              icon="left-long"
              size="sm"
              class="sort-icon"
              *ngIf="!sortByAsc"
            ></fa-icon>
          </span>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th class="fixed-column">{{ "global.series" | transloco }} ({{ 'global.shadow-model-series' | transloco }})</th>
                <th *ngFor="let date of productiveSince">{{ date }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let series of seriesList">
                <td class="fixed-column fixed-column-body">{{ series }}</td>
                <td *ngFor="let date of productiveSince">
                  <fa-icon *ngIf="hasIcon(series, date)" class= "version-icon" icon="circle-check"></fa-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div 
        class="tab-pane" 
        id="additionalInfo" 
        [ngClass]="{ 'active':activeTab==='additionalInfo'}"
      >
        <app-fcsc-filter-upload-list
          (refreshVisibleTable)="setFilter($event)"
          [data]="serialUploadListData"
        >
        </app-fcsc-filter-upload-list>
        <div class="minimum-height"></div>
        <div class="row">
          <div class="col-12 round-button-container">
            <icon-button
              [visible]="true"
              [buttonType]="'Page'"
              toolTipDescription="{{ 'global.download-csv' | transloco }}"
              [iconSelector]="'download'"
              (click)="downloadCSV()"
            ></icon-button>
          </div>
          <div class="col-12">
            <div class="vus-container">
              <ngx-datatable
                #dataTable
                class="material datatable-scrollable"
                [columnMode]="'flex'"
                [headerHeight]="56"
                rowHeight="auto"
                [scrollbarH]="false"
                [scrollbarV]="false"
                [rows]="serialUploadListData"
                [footerHeight]="56"
                (page)="setPage($event)"
                (sort)="sortCallback($event)"
                [sorts]="sortConfig"
                [messages]="{ emptyMessage: emptyMessage | transloco }"
              >
                <ngx-datatable-column
                  name="{{ 'global.series' | transloco }}"
                  prop="baureihe"
                  [flexGrow]="3"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.table-header.series'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{
                    'modules.data-management.serien-daten.upload-list.hist-date'
                      | transloco
                  }}"
                  prop="histDate"
                  [flexGrow]="4"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.hist-date'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{
                    'modules.data-management.serien-daten.upload-list.min-date'
                      | transloco
                  }}"
                  prop="minDatenstand"
                  [flexGrow]="5"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.tooltip.min-date'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{
                    'modules.data-management.serien-daten.upload-list.upload-date'
                      | transloco
                  }}"
                  prop="uploadDate"
                  [flexGrow]="5"
                  [resizeable]="false"
                  [sortable]="true"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.upload-date'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{
                    'modules.data-management.serien-daten.upload-list.contact-person'
                      | transloco
                  }}"
                  prop="contactPerson"
                  [flexGrow]="4"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.tooltip.contact-person'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{
                    'modules.data-management.serien-daten.upload-list.prio'
                      | transloco
                  }}"
                  prop="prio"
                  [flexGrow]="2"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.prio'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-right'"
                  name="{{
                    'modules.data-management.serien-daten.upload-list.requirement'
                      | transloco
                  }}"
                  prop="requirement"
                  [flexGrow]="3"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span *ngIf="value">{{ value }}</span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.serien-daten.upload-list.tooltip.requirement'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'global.comment' | transloco }}"
                  prop="comment"
                  [flexGrow]="5"
                  [resizeable]="false"
                >
                  <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                  >
                    <span
                      *ngIf="value"
                      class="text-pre-wrap"
                      placement="bottom"
                      [ngbTooltip]="value"
                      data-container="body"
                      container="body"
                      tooltipClass="note-tooltip"
                      >{{ truncateNote(value) }}
                    </span>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    let-sort="sortFn"
                    ngx-datatable-header-template
                  >
                    <span
                      class="header-sort"
                      ngbTooltip="{{
                        'modules.data-management.table-header.comment'
                          | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      (click)="sort()"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [cellClass]="'text-center'"
                  name="{{ 'global.actions' | transloco }}"
                  [flexGrow]="3"
                  [sortable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <icon-button
                      [visible]="true"
                      toolTipDescription="{{
                        'modules.data-management.serien-daten.upload-list.modal.title'
                          | transloco
                      }}"
                      [iconSelector]="'pencil'"
                      (click)="editSerialListRecord(row)"
                    ></icon-button>
                  </ng-template>

                  <ng-template
                    let-value="value"
                    let-column="column"
                    let-rowIndex="rowIndex"
                    ngx-datatable-header-template
                  >
                    <span
                      ngbTooltip="{{
                        'global.actions' | transloco : { value: value }
                      }}"
                      data-container="body"
                      container="body"
                      placement="bottom"
                      tooltipClass="note-tooltip"
                      >{{ column.name }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer *ngIf="true">
                  <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-offset="offset"
                    let-curPage="curPage"
                  >
                    <div class="col-8">
                      <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [count]="response?.totalElements || 0"
                        [page]="page.pageNumber"
                        [size]="page.size"
                        (change)="setPage($event)"
                      >
                      </datatable-pager>
                    </div>

                    <div class="col-4 page-result-section server-side">
                      <span class="float-end">
                        <span class="result-text">{{
                          "components.footer.result-per-page" | transloco
                        }}</span>
                        <span
                          class="result-value"
                          [ngClass]="{ active: page.size === 10 }"
                          (click)="setPageSize(10)"
                          >10</span
                        >|
                        <span
                          class="result-value margin-override"
                          [ngClass]="{ active: page.size === 30 }"
                          (click)="setPageSize(30)"
                          >30</span
                        >|
                        <span
                          class="result-value"
                          [ngClass]="{ active: page.size === 100 }"
                          (click)="setPageSize(100)"
                          >100</span
                        >
                        <span>
                          {{ "components.footer.files" | transloco }}:
                          {{
                            response && (response?.totalElements || 0) > 0
                              ? (response?.number || 0) * page.size + 1
                              : 0
                          }}
                          -
                          {{
                            isNaN(
                              (response?.number || 0) * page.size +
                                (response?.numberOfElements || 0)
                            )
                              ? 0
                              : (response?.number || 0) * page.size +
                                (response?.numberOfElements || 0)
                          }}
                        </span>
                        <span>
                          {{ "components.footer.of" | transloco }}
                          {{
                            response?.totalElements ? response?.totalElements : 0
                          }}</span
                        >
                      </span>
                    </div>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
