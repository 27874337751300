<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>
<div class="modal-header">
  <span class="modal-title">{{
    "modules.data-management.modal-upload-title" | transloco
  }}</span>
</div>

<div class="modal-sub-text">
  {{ "modules.data-management.modal-upload-subtitle" | transloco }}
</div>

<div class="modal-body" *ngIf="formLoaded">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label for="baureihe">{{
            "modules.data-management.title-sereis" | transloco
          }}</label>
          <ng-select
            *ngIf="showDiv.current"
            [searchable]="true"
            [clearable]="false"
            notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
            [hideSelected]="true"
            [items]="seriesList"
            [searchFn]="onSearchFn"
            bindLabel="brName"
            bindValue="brName"
            formControlName="selectedSeries"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ item.brName }}
            </ng-template>
            <ng-template
              ng-label-tmp
              ng-option-selected
              let-item="item"
              let-index="index"
            >
              {{ item.brName }}
            </ng-template>
          </ng-select>

          <input
            *ngIf="showDiv.previous"
            formControlName="selectedSeries"
            type="text"
            [readonly]="true"
            autocomplete="off"
            class="form-control"
            id="baureihe-input"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div [ngStyle]="{ visibility: showDiv.current ? 'visible' : 'hidden' }">
          <p class="modal-text-body">
            {{ "modules.data-management.modal-upload-area-title" | transloco }}
          </p>
        </div>
        <div
          class="form-group center"
          [ngClass]="{ 'disabled-opacity': checkSelection() }"
        >
          <div [ngClass]="{ 'disable-box': checkSelection() }"></div>
          <div *ngIf="showDiv.current">
            <ngx-file-drop [directory]="true" (onFileDrop)="dropped($event)">
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <div (click)="openFileSelector()">
                  <ul class="list-group droparea">
                    <li class="list-group-item">
                      <fa-icon icon="expand" class="item-icon"></fa-icon>
                    </li>
                    <li class="list-group-item">Drag&Drop</li>
                    <li class="list-group-item item-text">
                      &nbsp;{{
                        "modules.data-management.label-or" | transloco
                      }}&nbsp;
                    </li>
                    <li class="list-group-item">
                      <fa-icon icon="search" class="item-icon"></fa-icon>
                    </li>
                    <li class="list-group-item">
                      {{ "modules.data-management.label-browse" | transloco }}
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>

          <div class="scroll-container">
            <div class="upload-area" *ngIf="showDiv.previous">
              <ul
                class="list-group list-group-horizontal col-12"
                *ngFor="let item of filesData; let i = index"
              >
                <li class="list-group-item col-6">{{ item.name }}</li>
                <li class="list-group-item col-4">
                  {{ item.modified | date : "dd.MM.YY HH:mm:ss" }}
                </li>
                <li
                  class="list-group-item col-2"
                  *ngIf="item.isFileAllowed && item.isSerieAllowed"
                >
                  <fa-icon
                    icon="check"
                    size="sm"
                    class=""
                    *ngIf="item.valid === true"
                  ></fa-icon>
                </li>
                <li
                  class="list-group-item error col-2"
                  *ngIf="!(item.isFileAllowed && item.isSerieAllowed)"
                >
                  <fa-icon
                    icon="times"
                    size="sm"
                    class=""
                    *ngIf="item.valid === true"
                  ></fa-icon>
                </li>
              </ul>
            </div>
            <div class="scroll-space"></div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <button
            [disabled]="
              checkSelection() ||
              this.filesData.length === 0 ||
              this.disableDeltaUpload === true
            "
            type="button"
            class="btn btn-secondary"
            (click)="retrieveDeltaUploadFiles()"
          >
            {{ "modules.data-management.delta-upload.btn" | transloco }}
          </button>

          <div>
            <p
              [ngClass]="
                checkSelection() ||
                this.filesData.length === 0 ||
                this.disableDeltaUpload === true
                  ? 'modal-text-body disable-opacity'
                  : 'modal-text-body'
              "
              class="modal-text-body"
            >
              {{ "modules.data-management.delta-upload.title" | transloco }}
            </p>
          </div>
        </div>

        <div
          class="scroll-container"
          [ngClass]="{
            'disabled-opacity': checkSelection() || this.filesData.length === 0
          }"
        >
          <div class="upload-area delta">
            <ul
              class="list-group list-group-horizontal col-12"
              *ngFor="let item of deltaUploadfilesData; let i = index"
            >
              <li class="list-group-item col-6">{{ item.fileNameMissing }}</li>
              <li class="list-group-item col-4">
                {{ item.lastModified | date : "dd.MM.YY HH:mm:ss" }}
              </li>
            </ul>
          </div>
          <div class="scroll-space"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-5 form-group">
        <p>
          {{ countFiles() }}
        </p>
        <label for="name">{{
          "modules.data-management.label-sdp-name" | transloco
        }}</label>
        <input
          appTrim
          autocomplete="off"
          class="form-control"
          id="name"
          formControlName="name"
        />
      </div>

      <div class="col-12 form-group">
        <label for="note">{{ "global.comment" | transloco }}</label>
        <textarea
          [readonly]="showDiv.current"
          class="form-control"
          id="note"
          formControlName="note"
          maxlength="100"
          rows="3"
        ></textarea>
      </div>

      <div class="mt-3">
        <app-upload-duration
          [estimatedTime]="5"
          [translationKey]="'modules.data-management.sdp-files-upload'"
        ></app-upload-duration>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <div
            [ngStyle]="{ visibility: showDiv.previous ? 'visible' : 'hidden' }"
          >
            <button
              type="button"
              class="btn btn-secondary back"
              (click)="back()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
        </div>

        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ "global.cancel" | transloco }}
            </button>
            <button
              *appRequiredEntitlements="createBdpEntitlement"
              [disabled]="showDiv.current || disableUpload"
              type="button"
              class="btn btn-primary"
              (click)="upload()"
            >
              {{ "modules.data-management.btn-upload" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
