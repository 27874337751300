<nav class="header-container">
  <div class="header-left">
    <div>
      <span class="vpo">Verbaubarkeitsprüfung-Online (VPO)</span>
    </div>
  </div>
  <div class="header-right">
    <app-language-switch></app-language-switch>
    <div class="user-info">
      <fa-icon
      *appRequiredEntitlements="kibanaEntitlement"
      icon="poll"
      size="sm"
      class="header-icon info"
      placement="bottom"
      [ngbTooltip]="'components.header.kibana' | transloco"
      data-container="body"
      container="body"
      (click)="navigateToKibana()"
      tooltipClass="note-tooltip"
    ></fa-icon>
  
      <fa-icon
        icon="question-circle"
        size="sm"
        class="header-icon info"
        placement="bottom"
        [ngbTooltip]="'components.header.usermanual-download' | transloco"
        data-container="body"
        container="body"
        tooltipClass="note-tooltip"
        (click)="getUserManual()"
      ></fa-icon>
      <fa-icon
        icon="info-square"
        size="sm"
        class="header-icon info"
        placement="bottom"
        [autoClose]="false"
        #popover="ngbPopover"
        [ngbPopover]="popContent"
        placement="bottom"
        [ngbTooltip]="'Version - Info'"
        data-container="body"
        container="body"
        tooltipClass="note-tooltip"
      ></fa-icon>
   
        <fa-icon
          icon="user"
          size="sm"
          class="header-icon custom"
          placement="bottom"
          [autoClose]="false"
          placement="bottom"
          [ngbTooltip]="'components.header.entitlements-view' | transloco"
          data-container="body"
          container="body"
          tooltipClass="note-tooltip"
          (click)="viewEntitlements()"
        ></fa-icon>

      <span
        (click)="viewEntitlements()"
        class="profile"
        placement="bottom"
        [ngbTooltip]="'components.header.entitlements-view' | transloco"
        data-container="body"
        container="body"
        tooltipClass="note-tooltip"
        >{{ username }}</span
      >
    </div>
    <div class="signout">
      <fa-icon
        icon="sign-out"
        size="sm"
        class="header-icon"
        (click)="logout()"
      ></fa-icon>
    </div>
  </div>
</nav>
<ng-template #popContent>
  <app-version [popover]="popover"></app-version>
</ng-template>
<app-breadcrumb
  class="breadcrumb-pos"
  [application]="application"
  *ngIf="application && application.menus.length > 0"
></app-breadcrumb>
