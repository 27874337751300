<div class="modal-header">
      <span class="modal-title">
        {{ 'modules.admin-section.testautomation.test-detail.result-of' | transloco }}
        {{ test.timeStamp |date: 'dd.MM.YYYY, HH:mm:ss' }}
      </span>
  <div (click)="activeModal.dismiss()">
    <fa-icon class="close-icon" icon="times"></fa-icon>
  </div>
</div>
<div class="modal-header">
  <div class="test-result-section">
    <div class="test-result">
      <label class="label" *ngIf="test.successfulTestCases > 0">
        <fa-icon
          [style.color]="StatusColor.GREEN"
          icon="circle"
        ></fa-icon>
        {{ test.successfulTestCases }} {{ "modules.admin-section.testautomation.test-result.succeeded"  | transloco }}
      </label>
    </div>
    <div class="test-result">
      <label class="label" *ngIf="test.unsuccessfulTestCases > 0">
        <fa-icon
          [style.color]="StatusColor.RED"
          icon="circle"
        ></fa-icon>
        {{ test.unsuccessfulTestCases }} {{ "modules.admin-section.testautomation.test-result.failed"  | transloco }}
      </label>
    </div>
  </div>
</div>
<div class="modal-body checkbox-group">
  <label class="checkbox-container">
    <input
      type="checkbox"
      [checked]="showOnlyFailed"
      (change)="toggleFilter()"
    />
    <span class="checkmark"></span>
  </label>
  <label class="checkbox-label">
    {{ "modules.admin-section.testautomation.test-detail.show-failed"  | transloco }}
  </label>
</div>
<div class="modal-body">
  <div class="row">
    <ngx-datatable
      #dataTable
      class="material"
      [columnMode]="'flex'"
      [headerHeight]="56"
      [footerHeight]="56"
      [limit]="dataTableLimit"
      rowHeight="auto"
      [rows]="filteredDetails">

      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-detail.test-case' | transloco }}"
        prop="name"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span> {{ value }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'global.status' | transloco }}"
        prop="status"
        [flexGrow]="1"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <fa-icon
            [style.color]="value === 'PASSED' ? StatusColor.GREEN: StatusColor.RED"
            icon="circle"
          ></fa-icon>
          {{ value === 'PASSED' ? ('modules.admin-section.testautomation.test-detail.succeeded' | transloco) : ('modules.admin-section.testautomation.test-detail.failed' | transloco) }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer *ngIf="true">
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-offset="offset"
          let-curPage="curPage"
        >
          <div class="col-8">
            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                             [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                             [page]="curPage"
                             [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                             (change)="dataTable.onFooterPage($event)">
            </datatable-pager>
          </div>

          <div class="col-4 page-result-section server-side">
                <span class="float-end">
                  <span class="result-text">{{
                      "components.footer.result-per-page" | transloco
                    }}</span>
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 10 }"
                    (click)="setPageSize(10)"
                  >10</span
                  >|
                  <span
                    class="result-value margin-override"
                    [ngClass]="{ active: page.size === 30 }"
                    (click)="setPageSize(30)"
                  >30</span
                  >|
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 100 }"
                    (click)="setPageSize(100)"
                  >100</span
                  >
                </span>
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
