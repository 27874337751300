<div class="outer-container">
  <div class="vus-container">
    <label
      class="modal-title space-bottom">{{ 'modules.admin-section.testautomation.test-history.title' | transloco }}</label>
    <ngx-datatable
      #dataTable
      class="material"
      [columnMode]="'flex'"
      [headerHeight]="56"
      rowHeight="auto"
      [rows]="testRuns"
      [sorts]="sortConfig">

      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-timestamp' | transloco }}"
        prop="timeStamp"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value"> {{ value | date: "dd.MM.yy, HH:mm:ss" }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-duration' | transloco }}"
        prop="duration"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span>{{ formatTime(value) }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-succeeded' | transloco }}"
        prop="successfulTestCases"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <div class="status-box">
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <span *ngIf="value">
            <fa-icon
              [style.color]="StatusColor.GREEN"
              icon="circle"
            ></fa-icon>
              {{ value }} {{ 'modules.admin-section.testautomation.test-result.succeeded' | transloco }}</span>
          </ng-template>
        </div>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-failed' | transloco }}"
        prop="unsuccessfulTestCases"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">
          <fa-icon
            [style.color]="StatusColor.RED"
            icon="circle"
          ></fa-icon>

            {{ value }} {{ 'modules.admin-section.testautomation.test-result.failed' | transloco }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        name="{{ 'modules.admin-section.testautomation.test-history.test-user' | transloco }}"
        prop="username"
        [flexGrow]="3"
        [resizeable]="false"
      >

        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">{{ value }} </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [cellClass]="'text-center'"
        name="{{ 'global.actions' | transloco }}"
        [flexGrow]="2"
        [sortable]="false"
        [resizeable]="false"
      >
        <ng-template
          let-rowIndex="rowIndex"
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <icon-button
            [visible]="true"
            toolTipDescription="{{
                  'global.actions' | transloco
                }}"
            [iconSelector]="'eye'"
            (click)="detail(row)"
          ></icon-button>
          <icon-button
            [visible]="true"
            toolTipDescription="{{
              'global.actions' | transloco
            }}"
            [iconSelector]="'download'"
            (click)="download(row)"
          ></icon-button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

