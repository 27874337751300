<div class="cont">
  <div class="mainbody">
    <div class="modal__headerspace"> </div>
    <span class="float-to-right">
      <fa-icon
        icon="times"
        size="xl"
        class="close-icon"
        (click)="activeModal.dismiss()"
      ></fa-icon>
    </span>
    <div class="lockimage">
      <img src=" ./assets/forbidden/forbidden.svg" alt="Forbidden SVG" />
    </div>

    <div class="modal__content">
      <h1 class="modal__title">
        {{ "modules.expert-tool.result.log.modal.subtite-1" | transloco }}
      </h1>

    </div>
  </div>
</div>
